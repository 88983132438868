import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import "./Gallery.css";
import Subtract from "../../Images/Subtract.svg";
import axios from "axios";
import Video from "../Video/Video";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Drawer } from "antd";
import logo from "../../Images/logo.png";
import { Link } from "react-router-dom";
import {API_URL} from "../../actions/urlConfig"


const { TabPane } = Tabs;

export default function Gallery() {
  
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const [response, setresponse] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/apotech/gallery/image-view/`)
      .then((res) => {
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div id="gallery">
      <header>
        <div className="header-class">
          <div className="left-header">
            <Link to="/">
              <a href="d">
                <img src={logo} alt="logo" />
              </a>
            </Link>
          </div>

          <div className="right-header">
            <ul className="main-menu">
              <Link to="/">
                <li>
                  {" "}
                  <a href="#home"> Home </a>{" "}
                </li>
              </Link>

              <Link to="/gallery">
                <li>
                  {" "}
                  <a href="#home-slider">Gallery </a>{" "}
                </li>
              </Link>
              <li>
                {" "}
                <a href="#footers"> Contact us </a>{" "}
              </li>
            </ul>

            <div className="hamburger1">
              <MenuIcon
                onClick={() => showDrawer()}
                style={{ color: "#0F0F29", fontSize: "50px" }}
              />

              <Drawer
                title="Apotec"
                placement="right"
                closable={false}
                // onClose={onClose}
                visible={visible}
                style={{ color: "#0F0F29" }}
              >
                <p
                  style={{
                    color: "#0F0F29",
                    position: "absolute",
                    top: "9px",
                    right: "25px",
                  }}
                >
                  <CloseIcon
                    style={{ fontSize: "40px", color: "white" }}
                    onClick={onClose}
                  />
                </p>
                <div className="side-nav">
                  <Link to="/">
                    <a className="pro" href="d">
                      Home
                    </a>{" "}
                  </Link>
                  <Link to="/gallery">
                    <a href="#no" className="pro" onClick={onClose}>
                      Gallery
                    </a>{" "}
                  </Link>

                  <a className="pro" href="#footers" onClick={onClose}>
                    Contact us
                  </a>
                </div>
              </Drawer>
            </div>

            <div className="click">
              <span
                style={{ fontSize: "30px", cursor: "pointer" }}
                onclick="openNav()"
              >
                &#9776;{" "}
              </span>
            </div>
          </div>
        </div>
      </header>
      <h1>Gallery</h1>
      <div className="gallery-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Pictures" key="1">
            <div class="row">
              {response.map((rese) => (
                <div class="column">
                  <img src={rese.image} alt="gallery" />
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tab="Videos" key="2">
            <Video />
          </TabPane>
        </Tabs>
      </div>
      <div className="go-upto">
        <a className="go-down-up" href="#footers">
          <img src={Subtract} alt="subtract"></img>
        </a>
      </div>
    </div>
  );
}
