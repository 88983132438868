import React, { useState, useEffect } from "react";
import "./Video.css";
import axios from "axios";
import { API_URL } from "../../actions/urlConfig";

export default function Video() {
  const [response, setresponse] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/apotech/gallery/videos-view/`)
      .then((res) => {
        setresponse(res.data.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <div className="gallery-content">
        <ul>
          {response.map((rese) => (
            <li>
              <iframe
                src={rese.url}
                title="Iframe Example1"
                className="iframe"
              ></iframe>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
