import React from "react";
import "./Vision.css";
import Subtract from "../../Images/Subtract.svg";
import product from "../../Images/productname.svg"
import right from "../../Images/right.svg"

import apotecbrochure from "../../Images/APOTEC BROCHURE.pdf"
export default function Vision() {
  return (
    <div id="vision">
      <div className="content-mission">
        <div className="mission">
          <h1>Our Mission</h1>
          <p>
            To provide integral products and exceptional service to the chemical
            industry while sustaining a stable and consistent reputation through
            innovative intelligence.
          </p>
        </div>
        <div className="vision">
          <h1>Our Vision</h1>
          <p>
            Through adaptive improvement, we will continue to be a leader in the
            manufacturing of speciality chemicals. We will advance the
            speciality chemical intermediate business through our innovation,
            hard work and responsible care initiatives.
          </p>
        </div>
      </div>
      <div className="content-mission-copy">
        <div className="mission">
          <h1>Our Mission</h1>
          <p>
            To provide integral products and exceptional service to the chemical
            industry while sustaining a stable and consistent reputation through
            innovative intelligence.
          </p>
          </div>
          <div className="vision">
          <h1>Our Vision</h1>
          <p>
            Through adaptive improvement, we will continue to be a leader in the
            manufacturing of speciality chemicals. We will advance the
            speciality chemical intermediate business through our innovation,
            hard work and responsible care initiatives.
          </p>
        </div>
 

        
      </div>
      <img src={product} alt="product" className="product-images"></img>

      <a className="go-down" href="#home-slider">
        <img src={Subtract} alt="subtract"></img>
      </a>
      <button className="broucher"><a href={apotecbrochure} target="_blank">View Our Product Brochure</a> <img src={right} style={{marginTop:"4px"}}></img></button>
    </div>
  );
}
