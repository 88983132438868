import React, { useState, useEffect, useRef,useLayoutEffect } from "react";
import "./Home.css";
import logo from "../../Images/logo.png";
import Slider from "react-slick";
import one1 from "../../Images/one1.png";
import two2 from "../../Images/two2.png";
import three3 from "../../Images/three3.png";
import four4 from "../../Images/four4.png";
import five5 from "../../Images/five5.png";
import Subtract from "../../Images/Subtract.svg";
import axios from "axios";
import Vision from "../Vision/Vision";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Drawer } from "antd";
import downarrows from "../../Images/downarrows.png";
import line from "../../Images/line.png";
import { Link } from "react-router-dom";
import { API_URL } from "../../actions/urlConfig";
import Apotecm from "../../Images/Apotecm_GIF.gif";
import rewards from "../../Images/rewards.png";
import next from "../../Images/next.png";
import replacement from "../../Images/replacement.png";
import logoaptch  from "../../Images/logoaptch.svg"
import logohome from "../../Images/logo-aptch.svg"

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", marginLeft: "20px" }}
      onClick={onClick}
    >
      <img className="left-side"  alt="left" src={next} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

export default function Home() {


  
  const [navToggle, setnavToggle] = useState(false);
  const onpress = () => {
    setnavToggle(!navToggle);
  };

  const [activeScroll, setactiveScroll] = useState(false);

  const scrollFun = () => {
    if (window.scrollY > 0) {
      setactiveScroll(true);
    } else {
      setactiveScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollFun);
    return () => {
      window.removeEventListener("scroll", scrollFun);
    };
  }, []);







  /*--drawer--*/

  const [visible, setVisible] = useState(false);
  const sliderRef = useRef(null);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  /*--drawer--*/

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 530,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const [response, setresponse] = useState([]);

 
  

  useEffect(() => {
    axios
      .get(`${API_URL}/apotech/product/product-all/`)
      .then((res) => {
        setresponse(res.data.data);
       
        sliderRef.current.slickGoTo(0);
      
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="main-homepage">
      <header  className={navToggle || activeScroll ? "header-onscroll" : "header_wrap"}>
        <div  className= "header-class">
          <div className="left-header">
            <Link to="/">
              <a href="#home">
                <img src={logoaptch} alt="logo"  className={navToggle || activeScroll ? "logo-img-onscroll" : "logo-img"}/>
                <img src={logohome} alt="logo"  className={navToggle || activeScroll ? "logo-img-one-onscroll" : "logo-img-one"}/>

              </a>
            </Link>
          </div>

          <div className="right-header">
            <ul className="main-menu" >
              <li>
                {" "}
                <a href="#home"  className={navToggle || activeScroll ? "right-menu-onscroll" : "right-menu"}> Home </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#about"  className={navToggle || activeScroll ? "right-menu-onscroll" : "right-menu"}> About </a>{" "}
              </li>

              <li>
                {" "}
                <a href="#home-slider" className={navToggle || activeScroll ? "right-menu-onscroll" : "right-menu"}> Products </a>{" "}
              </li>
              <Link to="/gallery">
                <li>
                  {" "}
                  <a href="#home-slider" className={navToggle || activeScroll ? "right-menu-onscroll" : "right-menu"}>Gallery </a>{" "}
                </li>
              </Link>

              <li>
                {" "}
                <a href="#footers" onClick={onClose} className={navToggle || activeScroll ? "right-menu-onscroll" : "right-menu"}>
                  {" "}
                  Contact us{" "}
                </a>{" "}
              </li>
            </ul>

            <div className="hamburger1">
              <MenuIcon
              className={navToggle || activeScroll ? "menu-icon-onscroll" : "menu-icon"}
                onClick={() => showDrawer()}
              style={{fontSize:"50px"}}
              />

              <Drawer
                title="Apotec"
                placement="right"
                closable={false}
                // onClose={onClose}
                visible={visible}
                style={{ color: "#0F0F29" }}
              >
                <p
                  style={{
                    color: "#ffffff",
                    position: "absolute",
                    top: "9px",
                    right: "25px",
                  }}
                >
                  <CloseIcon
                    style={{ fontSize: "40px", color: "white" }}
                    onClick={onClose}
                  />
                </p>
                <div className="side-nav">
                  <Link to="/">
                    <a className="pro" href="#tabss" onClick={onClose}>
                      Home
                    </a>{" "}
                  </Link>
                  <a className="pro" href="#about" onClick={onClose}>
                    About
                  </a>{" "}
                  <a className="pro" href="#home-slider" onClick={onClose}>
                    Products
                  </a>{" "}
                  <Link to="/gallery">
                    <a href="#no" className="pro" onClick={onClose}>
                      Gallery
                    </a>{" "}
                  </Link>
                  <a className="pro" href="#footers" onClick={onClose}>
                    Contact us
                  </a>
                </div>
              </Drawer>
            </div>

            <div className="click">
              <span
                style={{ fontSize: "30px", cursor: "pointer" }}
                onclick="openNav()"
              >
                &#9776;{" "}
              </span>
            </div>
          </div>
        </div>
      </header>

      <div className="background-image">
        <div className="homepage-heading">
          SUSTAINABLE CHEMISTRY FOR BETTER TOMORROW
        </div>

        <div className="slider">
          <Slider ref={sliderRef} {...settings}>
            {response.map((res) => (
              <div className="first-image">
                <h1>{res.name}</h1>
                <img className="bottom-border" src={line} alt="line"></img>
                <p> {res.description}</p>

                <img src={res.image} alt="category"></img>

                <div className="tab-footer">
                  <h6>{res.category_name}</h6>
                </div>
              </div>
            ))}
          </Slider>
          <a className="go-down" href="#about">
            <img src={Subtract} alt="subtract"></img>
          </a>
        </div>
      </div>

      <div className="aboutus">
        <div id="about">
          <div className="about">
            <img src={Apotecm} alt="about"></img>
            <div
              className="about-use"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h1>About us</h1>
              <p>
                APOTEC SPECIALITY MATERIALS is a pioneering spirit, powered by
                entrepreneurship, expertise and enterprise. Our reputation has
                been built upon managing and handling diverse and multi-size
                industrial projects including those in the chemicals, resin, 
                coatings and building materials industries. APOTEC Speciality
                Materials is the chemicals arm of APOTEC group of companies,
                managed and driven by highly experienced technocrats with years
                of expertise in the core industry. We offer a wide range of
                construction chemicals for industrial floorings, protective
                coatings, structural repairs, waterproofing, adhesives, and
                sealants. Construction chemicals provide reliable solutions for
                the various market segments, such as engineering, construction,
                and interior design.
              </p>
            </div>
          </div>
          <div className="support">
            <ul>
              <li>
                <img src={one1} alt="support"></img>
                <div className="paragraph">
                  <h4>Pre-Application Support</h4>
                  <p>
                    {" "}
                    Our technical team visits the customer to inspect and
                    prepare the surface to ensure perfect condition for the
                    application process.
                  </p>
                </div>
              </li>
              <li>
                <img src={two2} alt="support"></img>
                <div className="paragraph">
                  <h4>Technical Guidance </h4>
                  <p>
                    {" "}
                    Complete guidance is given by our expert team at the time of
                    application.
                  </p>
                </div>
              </li>
              <li>
                <img src={three3} alt="support"></img>
                <div className="paragraph">
                  <h4>Technical Follow up</h4>
                  <p>
                    Regular follow up with the customer to ensure the
                    performance of the product.
                  </p>
                </div>
              </li>
              <li>
                <img src={four4} alt="support"></img>
                <div className="paragraph">
                  <h4>Online Support </h4>
                  <p>
                    24X7 online support provided by our executives through
                    social media platforms.
                  </p>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <img src={five5} alt="support"></img>
                <div className="paragraph">
                  <h4>Warranty and Guarantee </h4>
                  <p>
                    Assurances like warranty and guarantee is provided for every
                    products..
                  </p>
                </div>
              </li>
              <li>
                <img src={replacement} alt="support"></img>
                <div className="paragraph">
                  <h4>Replacement of Damaged Goods </h4>
                  <p>
                    Sold goods which are not in condition of usage is taken
                    aback and replacement with good quality product is ensured.
                  </p>
                </div>
              </li>
              <li>
                <img src={rewards} alt="support"></img>
                <div className="paragraph">
                  <h4>Rewards </h4>
                  <p>
                    Rewards like loyalty rewards, referral rewards and offers
                    are given to regular and reliable customers.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <a className="about-down" href="#vision">
            <img src={Subtract} alt="subtract"></img>
          </a>
        </div>
      </div>

      <div id="vision">
        <Vision />
      </div>

      <div id="home-slider">
        <h2>Our Products</h2>
        <div className="product-slider">
          <Slider {...settings1}>
            {response.map((res) => (
              <div className="product-full-container">
                <div className="product-left">
                  <img src={res.image} alt="product"></img>
                </div>
                <div className="product-right">
                  <div className="heading-button">
                    <div>
                      <h4> {res.name} </h4>{" "}
                      <img
                        className="bottom-bordersecond"
                        src={line}
                        alt="line"
                      ></img>
                    </div>
                    <button>{res.category_name}</button>
                  </div>
                  {/* <hr /> */}
                  <div className="product-list">
                    <p>{res.description}</p>

                    <div className="product">
                      <ul className="add-container">
                        <li className="addcontent">
                          {/* <img src={add} alt="add"></img> */}
                          {res.features.map((ress) => (
                            <p className="add-parg">{ress.name}</p>
                          ))}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <a className="go-down" href="#footers">
          <img src={downarrows} alt="subtract"></img>
        </a>
      </div>
    </div>
  );
}
