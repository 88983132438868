import "./App.css";
import Home from "./Components/HomePage/Home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Gallery from "./Components/Gallery/Gallery"
import Footer from "./Components/Footer/footer"

function App() {
  return (
    <div className="App">
      {/* <Home /> */}

      <BrowserRouter>
     
     <Switch>
       <Route exact path="/" component={Home} />{" "}
       <Route exact path="/gallery" component={Gallery} />{" "}



     </Switch>{" "}
     <Footer/>
   </BrowserRouter>
    </div>
  );
}

export default App;
