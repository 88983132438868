import React, { useState } from "react";
import corporate from "../../Images/corporate.svg";
import manufacturing from "../../Images/manufacturing.png";
import mail from "../../Images/mail.svg"
import tel from "../../Images/tel.svg"
import Instagram from "../../Images/Instagram.png";
import li from "../../Images/li.svg";
import Facebook from "../../Images/Facebook.png";
import whatsapp from "../../Images/what.svg";
import TextField from "@material-ui/core/TextField";
import { notification } from "antd";
import axios from "axios";
import Modals from "../Modals/Modals";
import link from "../../Images/link.svg";
import { Modal } from "antd";
import { API_URL } from "../../actions/urlConfig";

export default function Footer() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  /*--modal end--*/

  const [email, setemail] = useState("");
  const [content, setcontent] = useState("");

  const submit = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("content", content);
    axios
      .post(` ${API_URL}/apotech/contact/contact-us/`, bodyFormData)
      .then((res) => {
        setemail("");
        setcontent("");
        console.log(res.data);

        // window.scroll(0, 0);

        notification.open({
          message: "successfull",
          duration: 0,
        });
      })
      .catch((err) => {});
  };

  return (
    <div id="Footers-section">
      <div className="footer-section-container">
        <div id="footers">
          <h3>Contact us</h3>
          <div className="contact-containers">
            <div className="footer-left">
              <div className="contactme">
                <div className="corporates">
                  <span className="corporate-container">
                    <img src={corporate} alt="manufacturing"></img>{" "}
                    <h1>Corporate Address</h1>
                  </span>
                  <p className="first-paragraph">
                  Apotec speciality materials Pvt. Ltd.
                    #V25, 3rd Floor, JC Chamber <br/> Panampilly Nagar,
                    Ernakulam,<br/> Kerala - 682036
                  </p>
                  
                </div>

                <div className="Manufacturing">
                  <span className="corporate-container">
                    {" "}
                    <img src={manufacturing} alt="manufacturing"></img>{" "}
                    <h1>Regional Address</h1>
                  </span>
                  <p>
                    Apotec Speciality Materials Pvt. Ltd. Mancheri Building,
                    Payyanad  <br/> Manjeri,
                    Malappuram <br /> Kerala - 670122
                  </p>
                </div>
              </div>

              <ul>
                <li>
                  <a href="tel:+91 7356 25 26 07" className="footer-link">
                    <img src={tel} alt="phone"></img>04842314177
                  </a>
                </li>
                <li>
                  <a href="mailto:info@apotechem.com" className="footer-link">
                    <img src={mail} alt="phone"></img>info@apotechem.com
                  </a>
                </li>
                {/* <li>
                  <a href="www.apotechem.com" className="footer-link">
                    <img src={link} alt="phone"></img>www.apotechem.com
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="footer-right">
              <h1>Let us know your feedbacks and queries</h1>
              <div className="textfields">
                <TextField
                  id="outlined-basicone"
                  label="Email"
                  variant="outlined"
                  onChange={(event) => setemail(event.target.value)}
                  value={email}
                />
                <TextField
                  id="outlined-basic"
                  label="Message"
                  variant="outlined"
                  onChange={(event) => setcontent(event.target.value)}
                  value={content}
                />
              </div>
              <div className="send-message">
                <button
                  className="message"
                  onClick={() => {
                    submit();
                  }}
                >
                  Send message
                </button>
              </div>
            </div>
          </div>
          <div className="footer-socialmedia">
            <a href="https://www.facebook.com/Apotec-Speciality-Materials-100258791818838/">
              <img
                style={{ marginRight: "20px" }}
                src={Facebook}
                alt="facebook"
              ></img>
            </a>
            <a href="https://www.linkedin.com/company/apotec-speciality-materials">
              <img
                style={{ marginRight: "20px" }}
                src={li}
                alt="instagram"
              ></img>
            </a>
            <a href="https://instagram.com/apotechem?utm_medium=copy_link">
         
              <img
                style={{ marginRight: "20px" }}
                src={Instagram}
                alt="linkedin"
              ></img>
            </a>
            <img
              style={{ marginRight: "20px" }}
              src={whatsapp}
              onClick={showModal}
              alt="whatsapp"
            ></img>
          </div>
        </div>
        <div className="footer">
          <a href="https://www.techbyheart.in/"> Powered by techbyheart </a>
        </div>
      </div>

      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Modals />
      </Modal>
    </div>
  );
}
