import React from "react";
import Qrcode from "../../Images/Qrcode.png";
import qrlogo from "../../Images/qrlogo.png";
import "./Modals.css";

export default function Modal() {
  return (
    <div id="modal">
      <img src={qrlogo} alt="logo" className="logo-circle"></img>
      <div className="scan-container">
        <div className="white-background">
          <h1>Apotec Speciality Materials</h1>
          <h2>WhatsApp Buisness Account</h2>

          <img src={Qrcode} alt="logo"></img>
        </div>
        <div className="scan">
          <p>
            Scan this QR Code to start a WhatsApp chat with Apotec Speciality
            Materials.
          </p>
        </div>
      </div>
    </div>
  );
}
